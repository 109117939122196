/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  display: inline-block;
  transition: 0.25s;
  &:hover {
    transform: scale(1.05);
  }

  ${props => props.grey ? css`
    border: 1px solid ${props.theme.color.grey};
  ` : css`
    border: 1px solid ${props.theme.color.main};
  `}

  & > a, & > button {
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
    text-transform: uppercase;
    font-weight: ${props => props.theme.font.weight.m};
    
    ${props => props.grey ? css`
      color: ${props.theme.color.text.main};
    ` : css`
      color: ${props.theme.color.text.contrast};
    `}
  }
`

const ButtonDefaultOutline = ({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  grey,
  ...rest
}) => (
  <StyledButton className={className} grey={grey}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell {...rest} to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefaultOutline
