import React from 'react'

// Images
import downloadIcon from 'img/download.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'
import ButtonDefaultOutline from 'components/elements/ButtonDefaultOutline'
import ServicesMenu from 'components/sidebar/ServicesMenu'

// Third Party
import styled from 'styled-components'

const BrochureRow = styled.div`
  margin-top: -164px;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Brochure = ({ brochure }) => {
  return (
    <div className="col-lg-6 pl-3">
      <BrochureRow className="row justify-content-center mb-5">
        <div className="col-lg-8">
          <div className="d-flex flex-column justify-content-center align-items-center color-background-lightGrey px-5 p-4">
            <h3 className="color-text-contrast font-weight-light mb-3">{brochure.title}</h3>
            <ButtonDefault className="w-100 mb-3" to={brochure.contactbutton.url}>
              {brochure.contactbutton.title}
            </ButtonDefault>
            {brochure.download && brochure.download.file && brochure.download.file.localFile && (
              <ButtonDefaultOutline target="_blank" rel="noreferrer noopener" isAnchor className="w-100 text-center d-flex align-items-center" to={brochure.download.file.localFile.publicURL}>
                {brochure.download.title}
                <img className="ml-3" src={downloadIcon} alt="" />
              </ButtonDefaultOutline>
            )}
          </div>
        </div>
      </BrochureRow>

      <div className="row justify-content-center">
        <div className="col-lg-6">
          <ServicesMenu />
        </div>
      </div>
    </div>
  )
}

export default Brochure
