/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import Hero from 'components/elements/Hero'
import ProjectsSection from 'components/shared/ProjectsSection'
import KoelMonteurs from 'components/shared/before-footer/Koelmonteurs'
// import { ArrowLinkAnchor } from 'components/elements/ArrowLink'
import Image from 'components/elements/Image'
import Brochure from 'components/sidebar/Brochure'
import OnzeWerkwijze from '../components/OnzeWerkwijze'

import { getLanguage } from 'services/language'

// import downloadIcon from 'img/download.svg'

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      content
      locale {
        locale
      }
      templateMarketDetails {
        bannermarket {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
        }

        intromarket {
          title
          description
        }

        brochuremarket {
          title
          contactbutton {
            title
            url
          }
          download {
            title
            file {
              localFile {
                publicURL
              }
            }
          }
        }
        component {
          ... on WpComponent {
            databaseId
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const language = getLanguage()

const PageTemplate = ({
  data: {
    page: { locale, seo, templateMarketDetails },
  },
}) => {
  return (
    <Layout>
      {seo && (
        <SEO
          seo={seo}
          socialImage={
            templateMarketDetails.bannermarket.image.localFile.childImageSharp
              .meta.src
          }
        />
      )}
      <Hero
        src={templateMarketDetails.bannermarket.image}
        title={templateMarketDetails.bannermarket.title}
      />
      <section className="container pl-lg-5 py-5">
        <div className="row">
          <div className="col-lg-6 pr-5">
            <div className="ml-lg-n5 mb-5">
              <ParseContent content={templateMarketDetails.intromarket.title} />
            </div>
            <ParseContent
              content={templateMarketDetails.intromarket.description}
            />
          </div>

          <Brochure brochure={templateMarketDetails.brochuremarket} />
        </div>
      </section>
      <section className="container position-relative pl-lg-5 mb-5">
        {/* <div>
          <ParseContent content={acf.proces.title} />
        </div> */}

        {/* <ArrowLinkAnchor className="mb-5" href={acf.proces.download.localFile.publicURL} target="_blank" rel="noreferrer noopener">
          {acf.proces.downloadText}
          <img className="ml-2" src={downloadIcon} alt="" />
        </ArrowLinkAnchor> */}

        {/* {templateMarketDetails.proces.images.map((image, index) => (
          image && image.image.localFile && image.image.localFile.publicURL ? (
            <a className="w-100" href={image.image.localFile.publicURL} download>
              <Image className="w-100" key={index} src={image.image} />
            </a>
          ) : (
            <a className="w-100" href={image.image} download>
              <Image className="w-100" key={index} src={image.image} />
            </a>
          )
        ))} */}
      </section>

      <OnzeWerkwijze id={locale.locale === 'nl_NL' ? 2510 : 2542} />

      {/* <ProjectsSection /> */}
      <KoelMonteurs />
    </Layout>
  )
}

export default PageTemplate
