/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Helpers
import { getLanguage } from 'services/language'

// Components
import Img from 'gatsby-image'
import Box from 'components/elements/Box'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  min-height: 281px;
  transition: 0.25s;
`

const Title = styled.p`
  font-size: ${props => props.theme.font.size.m};
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
`

const StyledBox = styled(Box)`
  padding: 1rem !important;
  bottom: 20px;
  left: 0px;
`

const ImgContainer = styled.div`
  overflow: hidden;
`

const StyledCustomLink = styled(CustomLink)`
  &:hover {
    ${StyledImg} {
      transform: scale(1.05);
    }
  }
`

const Projects = ({ projectIds }) => {
  const language = getLanguage()
  const { projectsNL, projectsEN } = useStaticQuery(graphql`
    {
      projectsNL: allWpProject(filter: {locale: {locale: {eq: "nl_NL"}}}) {
        edges {
          node {
            ...ProjectsFrag
          }
        }
      }

      projectsEN: allWpProject(filter: {locale: {locale: {eq: "en_US"}}}) {
        edges {
          node {
            ...ProjectsFrag
          }
        }
      }
    }
  `)

  let projectPost = language === 'nl_NL' ? projectsNL.edges : projectsEN.edges

  if (projectIds && projectIds.length > 0) {
    projectPost = projectPost.filter(
      ({ node: { wpId } }) => projectIds.indexOf(wpId) !== -1
    )
  }

  return (
    <>
      {projectPost.map(({ node: project }) => (
        <StyledCustomLink to={project.path} key={project.wpId} className="col-lg col-md-4 col-sm-6 py-2 py-lg-0 px-2">
          <ImgContainer className="position-relative">
            <StyledImg loading="eager" fadeIn={false} alt="" fluid={project.acf.excerpt.image.localFile.childImageSharp.fluid} />
            <StyledBox opacity className="position-absolute col-lg-6 mb-4">
              <Title>{project.title}</Title>
            </StyledBox>
          </ImgContainer>
        </StyledCustomLink>
      ))}
    </>
  )
}

export default Projects
