import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

// Helpers
import { getLanguage } from 'services/language'

import ParseContent from './ParseContent'
import Projects from '../elements/Projects'
import ButtonDefaultOutline from '../elements/ButtonDefaultOutline'
import Box from '../elements/Box'


const ProjectsTitle = styled(Box)`
  background-color: ${props => props.theme.color.alt};

  h3 {
    color: ${props => props.theme.color.text.main};
    font-size: ${props => props.theme.font.size.l};
    font-weight: ${props => props.theme.font.weight.s};
    line-height: 20px;
    margin-bottom: 0;
  }

  &::before {
    content: '';
    background-color: ${props => props.theme.color.alt};
    width: 1100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: -1100%;
  }
`

const ProjectsRow = styled.div`
  & p {
    margin-bottom: 0;
  }
`

const ContentContainer = styled.div`
  @media screen and (max-width: 991px) {
    br {
      display: none;
    }
  }
`

const ProjectsSection = () => {
  const language = getLanguage()
  const { componentNL, componentEN } = useStaticQuery(graphql`
    query {
      componentNL : wpComponent(databaseId: {eq: 254 }) {
        ...ProjectSectionFrag
      }

      componentEN : wpComponent(databaseId: {eq: 687 }) {
        ...ProjectSectionFrag
      }
    }
  `)

  const acf = language === 'nl_NL' ? componentNL.componentProjecten : componentEN.componentProjecten

  return (
    <section>
      <div className="container">
        <ProjectsRow className="row pt-5 align-items-center">
          <div className="col-lg-5 p-0">
            <ProjectsTitle>
              <h3>{acf.title}</h3>
            </ProjectsTitle>
          </div>
          <ContentContainer className="col-lg-7 py-3 py-lg-0">
            <ParseContent content={acf.description} />
          </ContentContainer>
        </ProjectsRow>
      </div>
      <div className="row flex-wrap justify-content-center">
        <Projects projectIds={acf.projects.map((id) => id)} />
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 d-flex justify-content-center">
            <ButtonDefaultOutline to={acf.gotoall.url}>
              {acf.gotoall.title}
            </ButtonDefaultOutline>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectsSection
